
import {computed, defineComponent, ref, watch} from "vue";
import {
  alertController,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  isPlatform


} from "@ionic/vue";
import {useStore} from "vuex";
import router from "@/app/router";
import {useRoute} from "vue-router";
import {ExitDelivery, ExitDeliveryItem} from "@/domain/model/delivery/delivery.model";
import DetailsExitDeliveryItemComponent from "@/app/view/delivery/details/DetailsExitDeliveryItemComponent.vue";
import {Order} from "@/domain/model/order/order.model";
import {
  CreateExitDelivery, CreateExitDeliveryItem,
  emptyExitDeliveryCommand, UpdateExitDelivery,
  updateExitDeliveryFromEntity
} from "@/domain/command/delivery/delivery.command";
import _ from "lodash";
import CreateExitDeliveryOrdersCardComponent
  from "@/app/view/delivery/create/CreateExitDeliveryOrdersCardComponent.vue";
import CreateExitDeliveryTableItemComponent from "@/app/view/delivery/create/CreateExitDeliveryTableItemComponent.vue";
import {useViewStateApi} from "@/domain/service/api/viewState.api";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";

export default defineComponent({
  name: "detailsExitDelivery",
  components: {
    CreateExitDeliveryOrdersCardComponent,
    CreateExitDeliveryTableItemComponent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonButton,
    IonInput,
    IonLabel,
    IonTitle,
    IonList,
    IonItemDivider,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const id = ref(route.params.id);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const orders = computed<Order[]>(() => store.getters.allActiveOrders);
    const command = ref<UpdateExitDelivery>(emptyExitDeliveryCommand());
    const exitDelivery = computed<ExitDelivery>(() => store.getters.exitDeliveryById(id.value));
    const deliveryIndex = ref<string>(exitDelivery.value.indexPosition);
    const originalIndex = ref<string>(deliveryIndex.value);

    watch(exitDelivery, () => {
      const updateExitDelivery: any = _.cloneDeep(exitDelivery.value);
      updateExitDelivery.items = updateExitDelivery.items.map((deliveryItem: any) => {
        const item = _.cloneDeep(deliveryItem);
        const ref = store.getters.orderById(deliveryItem.producedOrder.orderId).material.ref;
        item.ref = ref;
        item.producedOrder.ref = ref;
        return item;
      });
      command.value = updateExitDeliveryFromEntity(updateExitDelivery);
    }, {
      immediate: true
    });
    const onIndexIsNotFree = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Número de albarán de salida inválido',
            message: 'El número de albarán de salida  que ha introducido ya existe o está vacío, introduzca uno distinto',
            buttons: [
              {
                text: 'Ok',
                role: 'success',
                cssClass: 'danger',
              },
            ],
          });

      await alert.present();

      const {role} = await alert.onDidDismiss();
    }


    const filterValidItems = (items: Array<any>) => _.reject(items, (item) => {
      return item.reference === ''
    });
    const isAtLeastOneItem = computed<boolean>(() => {
      return filterValidItems(command.value.items).length > 0
    });

    const onSendToCheck = async () => {
      const newCommand: UpdateExitDelivery = command.value;
      newCommand.items = filterValidItems(newCommand.items);
      if(!deliveryIndex.value){
        await onIndexIsNotFree();
        return;
      }
      newCommand.indexPosition = deliveryIndex.value;
      await useDeliveryApi().isExitDeliveryIndexUsed(_.cloneDeep(deliveryIndex.value));
      if(!store.getters.getIsThisIndexFree && deliveryIndex.value !== originalIndex.value){
        await onIndexIsNotFree();
        return;
      }
      useViewStateApi().updateExitDeliveryBeforeCheck(newCommand);
      await router.push({name: `checkUpdateExitDelivery`, params: {id: newCommand.id}});
    };

    const goBack = async () => {
      await router.back();
    };

    const onUpdateItems = (items: CreateExitDeliveryItem[]) => {
      command.value.items = items;
    }

    return {
      onSendToCheck,
      goBack,
      exitDelivery,
      isTablet,
      command,
      orders,
      isAtLeastOneItem,
      onUpdateItems,
      deliveryIndex,
    };
  }
})
